import Vue from "vue";
import App from "./App.vue";
import VueGtm from "@gtm-support/vue2-gtm";
import VueWaypoint from "vue-waypoint";
Vue.config.productionTip = false;

// Waypoint plugin
Vue.use(VueWaypoint);

Vue.use(VueGtm, {
  id: "GTM-N7FW4DX",
});

new Vue({
  render: (h) => h(App),
}).$mount("#app");
