<template lang="pug">
.app(:data-step="step")
  .app-header.bg-light.d-flex.align-items-center.ps-3
    img.app-logo.w-auto(src="@/assets/images/logo.png")

  .container-xxl.pb-3
    .step.step-terms(v-if="step === 1")
      terms
      div(v-waypoint='{ active: true, callback: onWaypoint, options: intersectionOptions }')
    .step.step-register(v-if="step === 2")
      //- h2.title.text-center.text-primary.border-bottom.pb-3.mb-4 ลงทะเบียน
      form#register.d-grid.gap-4.mb-4.pt-3(@submit.prevent="onSubmit", novalidate)
        .text-gradient.fs-3.fw-bold ข้อมูลส่วนตัว

        .form-group
          label.form-label.fs-14.mb-3.opacity-50(for="sex") เพศ
          select.form-select.form-select-lg(v-model="form.sex" required)
            option(value="" disabled) เลือกเพศ
            option(value="ชาย") ชาย
            option(value="หญิง") หญิง
          .invalid-feedback กรุณาเลือกเพศ

        .form-group
          label.form-label.fs-14.mb-3.opacity-50(for="age") ช่วงอายุ
          select.form-select.form-select-lg(v-model="form.age" required)
            option(value="" disabled) เลือกช่วงอายุ
            option(v-for="{label, value} in ageList" :key="value" :value="value") {{ label }}
          .invalid-feedback กรุณาเลือกช่วงอายุ'
          

        .form-group
          label.form-label.fs-14.mb-3.opacity-50(for="occupation") กลุ่มอาชีพ
          select.form-select.form-select-lg(v-model="form.occupation" required)
            option(value="" disabled) เลือกกลุ่มอาชีพ
            option(v-for="occupation in occupationList" :key="occupation" :value="occupation") {{ occupation }}
          .invalid-feedback กรุณาเลือกกลุ่มอาชีพ

        //- 
        .form-group
          label.form-label.fs-14.mb-3.opacity-50(for="phone") จังหวัด
          select.form-select.form-select-lg(v-model="form.province" required)
            option(value="" disabled) เลือกจังหวัด
            option(v-for="{ province } in provinces" :value="province") {{ province }}
          .invalid-feedback กรุณาเลือกจังหวัด

        .text-gradient.fs-3.fw-bold 1. ความเป็นที่รู้จัก    

        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="answer1_1") ท่านรู้จัก LINE Official BAAC Family จากช่องทางใด (โปรดระบุ)
          input#answer1_3.form-control.form-control-lg(
            type="text",
            v-model="form.answer1_1",
            name="answer1_1"
            required
          )
          .invalid-feedback กรุณาระบุ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณเคยเห็นสื่อบริการของ BAAC Family จากสื่อออนไลน์
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices1",
              :key="choice.value",
              :for="`answer1-2-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer1_2",
                  type="radio",
                  :value="choice.value",
                  :id="`answer1-2-${choice.value}`"
                  name="answer1-2"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer1_2 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณเคยเห็นสื่อบริการของ BAAC Family จากสื่อออฟไลน์ 
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices1",
              :key="choice.value",
              :for="`answer1-3-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer1_3",
                  type="radio",
                  :value="choice.value",
                  :id="`answer1-3-${choice.value}`"
                  name="answer1-3"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer1_3 && submited }"
          ) กรุณาเลือก คำตอบ

        .text-gradient.fs-3.fw-bold 2. การใช้บริการ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block ท่านเคยเล่นกิจกรรมรายเดือนหรือกิจกรรม แอดไลน์แจกทอง ผ่าน LINE Official BAAC Family
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices1",
              :key="choice.value",
              :for="`answer2-1-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer2_1",
                  type="radio",
                  :value="choice.value",
                  :id="`answer2-1-${choice.value}`"
                  name="answer2-1"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer2_1 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block ท่านเคยดาวน์โหลดสติ้กเกอร์ไลน์ของ ธ.ก.ส. ผ่าน LINE Official BAAC Family
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices1",
              :key="choice.value",
              :for="`answer2-2-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer2_2",
                  type="radio",
                  :value="choice.value",
                  :id="`answer2-2-${choice.value}`"
                  name="answer2-2"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer2_2 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block ท่านใช้ช่องทาง LINE Official BAAC Family ในการศึกษาข้อมูลผลิตภัณฑ์และบริการหรือไม่
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices1",
              :key="choice.value",
              :for="`answer2-3-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer2_3",
                  type="radio",
                  :value="choice.value",
                  :id="`answer2-3-${choice.value}`"
                  name="answer2-3"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer2_3 && submited }"
          ) กรุณาเลือก คำตอบ


        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block ท่านใช้บริการใดผ่าน LINE Official BAAC Family 
          small (เลือกได้มากกว่า 1 คำตอบ)
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices2",
              :key="choice.value",
              :for="`answer2-4-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer2_4",
                  type="checkbox",
                  :value="choice.value",
                  :id="`answer2-4-${choice.value}`"
                  name="answer2-4"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")

            input#answer2_4_1.form-control.form-control-lg(
              v-if="form.answer2_4.includes(5)"
              type="text",
              v-model="form.answer2_4_1",
              name="answer2_4_1"
              placeholder="โปรดระบุ"
              required
            )
            .invalid-feedback กรุณาระบุ
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer2_4.length && submited }"
          ) กรุณาเลือก คำตอบอย่างน้อย 1 คำตอบ


        div
          .text-gradient.fs-3.fw-bold 3. ความพึงพอใจต่อ LINE Official BAAC Family 
          .fs-5.d-block.opacity-50 ให้คะแนนความพึงพอใจที่ 1 ถึง 5 (1 = พึงพอใจน้อยที่สุด และ 5 = พึงพอใจมากที่สุด) 

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block ท่านพึงพอใจในช่องทาง LINE Official BAAC Family 
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer3-1-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer3_1",
                  type="radio",
                  :value="choice.value",
                  :id="`answer3-1-${choice.value}`"
                  name="answer3-1"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer3_1 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณรู้สึกว่าเมนูรายการของ LINE Official BAAC Family เข้าใจง่าย  
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer3-2-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer3_2",
                  type="radio",
                  :value="choice.value",
                  :id="`answer3-2-${choice.value}`"
                  name="answer3-2"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer3_2 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณคิดว่าการใช้บริการผ่าน LINE Official BAAC Family ใช้งานง่าย
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer3-3-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer3_3",
                  type="radio",
                  :value="choice.value",
                  :id="`answer3-3-${choice.value}`"
                  name="answer3-3"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer3_3 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณคิดว่าการใช้งาน LINE Official BAAC Family ทำให้คุณประหยัดเวลามากขึ้น 
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer3-4-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer3_4",
                  type="radio",
                  :value="choice.value",
                  :id="`answer3-4-${choice.value}`"
                  name="answer3-4"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer3_4 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณคิดว่าการใช้งาน LINE Official BAAC Family ทำให้คุณเข้าถึงข่าวสารจากธนาคารได้ง่ายขึ้น 
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer3-5-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer3_5",
                  type="radio",
                  :value="choice.value",
                  :id="`answer3-5-${choice.value}`"
                  name="answer3-5"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer3_5 && submited }"
          ) กรุณาเลือก คำตอบ


        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณคิดว่าข้อมูล และ ความรู้ที่ได้จาก LINE Official BAAC Family เป็นประโยชน์ 
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer3-6-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer3_6",
                  type="radio",
                  :value="choice.value",
                  :id="`answer3-6-${choice.value}`"
                  name="answer3-6"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer3_6 && submited }"
          ) กรุณาเลือก คำตอบ


        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณคิดว่าข้อมูลและความรู้ที่ได้จาก LINE Official BAAC Family เข้าใจง่าย
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer3-7-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer3_7",
                  type="radio",
                  :value="choice.value",
                  :id="`answer3-7-${choice.value}`"
                  name="answer3-7"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer3_7 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณมักจะเปิดข้อความอ่านจาก LINE Official  BAAC Family ประมาณกี่ครั้งต่อสัปดาห์
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices4",
              :key="choice.value",
              :for="`answer3-8-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer3_8",
                  type="radio",
                  :value="choice.value",
                  :id="`answer3-8-${choice.value}`"
                  name="answer3-8"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer3_8 && submited }"
          ) กรุณาเลือก คำตอบ


        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณมักจะร่วมกิจกรรมที่เกิดขึ้นผ่านระบบ LINE Official BAAC Family
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer3-9-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer3_9",
                  type="radio",
                  :value="choice.value",
                  :id="`answer3-9-${choice.value}`"
                  name="answer3-9"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer3_9 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block ท่านมีข้อเสนอแนะการให้บริการ ผ่าน LINE Official BAAC Family (โปรดระบุ)
          textarea#address.form-control.form-control-lg(
            v-model="form.answer3_10",
            rows="2",
            required
            name="answer3_10"
          )
          .invalid-feedback โปรดระบุ

        div
          .text-gradient.fs-3.fw-bold 4. ความพึงพอใจต่อ Chatbot BAAC Family 
          .fs-5.d-block.opacity-50 ให้คะแนนความพึงพอใจที่ 1 ถึง 5 (1 = พึงพอใจน้อยที่สุด และ 5 = พึงพอใจมากที่สุด) 

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณรู้สึกไม่อึดอัดกับการใช้งาน chatbot ของ BAAC Family
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer4-1-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer4_1",
                  type="radio",
                  :value="choice.value",
                  :id="`answer4-1-${choice.value}`"
                  name="answer4-1"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer4_1 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณรู้สึกว่า chatbot ของ BAAC Family สามารถเอื้ออำนวยความสะดวกให้คุณได้
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer4-2-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer4_2",
                  type="radio",
                  :value="choice.value",
                  :id="`answer4-2-${choice.value}`"
                  name="answer4-2"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer4_2 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณคิดว่า chatbot ของ BAAC Family สามารถให้ข้อมูลที่เป็นประโยชน์กับคุณได้
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer4-3-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer4_3",
                  type="radio",
                  :value="choice.value",
                  :id="`answer4-3-${choice.value}`"
                  name="answer4-3"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer4_3 && submited }"
          ) กรุณาเลือก คำตอบ


        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณอยากจะใช้งาน chatbot ของ BAAC Family อีกบ่อยๆ 
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer4-4-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer4_4",
                  type="radio",
                  :value="choice.value",
                  :id="`answer4-4-${choice.value}`"
                  name="answer4-4"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer4_4 && submited }"
          ) กรุณาเลือก คำตอบ


        div
          .text-gradient.fs-3.fw-bold 5. ความพึงพอใจต่อ Chatbot หอมจัง
          .fs-5.d-block.opacity-50 ให้คะแนนความพึงพอใจที่ 1 ถึง 5 (1 = พึงพอใจน้อยที่สุด และ 5 = พึงพอใจมากที่สุด) 

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณรู้สึกสะดวกใจในการพูดคุยกับ chatbot หอมจัง
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer5-1-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer5_1",
                  type="radio",
                  :value="choice.value",
                  :id="`answer5-1-${choice.value}`"
                  name="answer5-1"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer5_1 && submited }"
          ) กรุณาเลือก คำตอบ


        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณรู้สึกข้อมูลที่ได้จาก chatbot หอมจังเป็นประโยชน์กับคุณ 
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer5-2-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer5_2",
                  type="radio",
                  :value="choice.value",
                  :id="`answer5-2-${choice.value}`"
                  name="answer5-2"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer5_2 && submited }"
          ) กรุณาเลือก คำตอบ


        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณคิดว่าการใช้งาน chatbot หอมจังใช้งานง่าย
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer5-3-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer5_3",
                  type="radio",
                  :value="choice.value",
                  :id="`answer5-3-${choice.value}`"
                  name="answer5-3"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer5_3 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณอยากแนะนำ chatbot หอมจังให้เพื่อนหรือคนที่คุณรู้สึกได้ลองใช้งาน 
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices3",
              :key="choice.value",
              :for="`answer5-4-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer5_4",
                  type="radio",
                  :value="choice.value",
                  :id="`answer5-4-${choice.value}`"
                  name="answer5-4"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer5_4 && submited }"
          ) กรุณาเลือก คำตอบ

        .text-gradient.fs-3.fw-bold 6. อยากแนะนำให้คนรู้จัก

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณอยากแบ่งปันข้อมูลที่ได้จาก LINE Official BAAC Family ให้กับเพื่อนและคนรู้จักของคุณ 
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices1",
              :key="choice.value",
              :for="`answer6-1-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer6_1",
                  type="radio",
                  :value="choice.value",
                  :id="`answer6-1-${choice.value}`"
                  name="answer6-1"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer6_1 && submited }"
          ) กรุณาเลือก คำตอบ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณอยากบอกต่อกิจกรรมใน LINE Official BAAC Family ให้เพื่อนหรือคนที่รู้จักรู้  
          .d-grid.gap-3
            label.w-100(
              v-for="(choice, index) in choices1",
              :key="choice.value",
              :for="`answer6-2-${choice.value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.answer6_2",
                  type="radio",
                  :value="choice.value",
                  :id="`answer6-2-${choice.value}`"
                  name="answer6-2"
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
              
          .invalid-feedback(
            :class="{ 'd-block': !form.answer6_2 && submited }"
          ) กรุณาเลือก คำตอบ

        .alert.alert-danger(ref="alert" v-if="errorMessages" v-html="errorMessages")

    .step.step-success.text-center.d-flex.align-items-center(v-if="step === 3")
      .w-100
        icon-check-2.mb-4
        h3.text-gradient.fs-2.mb-4 เสร็จเรียบร้อย
        p ขอบคุณสำหรับการตอบแบบคำถาม
        happy-student.mb-5

    .app-action.position-fixed.start-0.end-0.bottom-0.p-3
      template(v-if="step === 1")
        .pb-2.text-center.fs-14(for="acceptTerm") ข้าพเจ้ายอมรับในข้อตกลงเเละเงื่อนไข
        .d-flex.gap-2

          button.btn.btn-lg.btn-secondary.w-50(
            :disabled="!hasScrolledToBottom || isLoading"
            :class="{ 'opacity-50' : !hasScrolledToBottom }"
            @click="success"
          ) ไม่ยอมรับ

          button.btn.btn-gradient.btn-lg.btn-block.text-white.w-50(
            type="button",
            :disabled="!hasScrolledToBottom || isLoading",
            @click="acceptTerm",
            :class="{ 'opacity-50' : !hasScrolledToBottom }"
          ) 
            span.spinner-border.spinner-border-sm(
              v-if="isLoading",
              role="status",
              aria-hidden="true"
            )
            span(v-else) ยอมรับ

      template(v-if="step === 2")
        button.btn.btn-gradient.btn-lg.btn-block.text-white.w-100(
          type="submit",
          :disabled="isLoading",
          @click="onSubmit"
        ) 
          span.spinner-border.spinner-border-sm(
            v-if="isLoading",
            role="status",
            aria-hidden="true"
          )
          span(v-else) ส่งคำตอบ

      template(v-if="step === 3 || step === 4")
        button.btn.btn-primary.btn-lg.btn-block.rounded-pill.text-white.w-100(
          type="button",
          @click="success"
        )
          span(v-if="step === 4") ปิดหน้าต่าง
          span(v-else) เสร็จสิ้น
</template>

<script>
// import axios from "axios";
const { GoogleSpreadsheet } = require("google-spreadsheet");
const creds = require("@/client_secret.json");
import "./assets/bootstrap.scss";

import provinces from "@/data/provinces.json";
import liff from "@line/liff";
import Terms from "@/components/Terms.vue";
import HappyStudent from "@/components/HappyStudent.vue";
import IconCheck from "@/components/Check.vue";
import IconCheck2 from "@/components/Check2.vue";
export default {
  components: { Terms, HappyStudent, IconCheck, IconCheck2 },
  data() {
    return {
      sheetId: "1hbWpvb0wbO7rBZJQBrGiODDQHopZkfmQEAMBpcalwT8",
      provinces: provinces.data,
      form: {
        province: "",
        sex: "",
        age: "",
        occupation: "",
        answer2_4: [],
      },
      step: 1,
      profile: {},
      isLoading: false,
      submited: false,
      errorMessages: "",
      hasScrolledToBottom: false,
      ageList: [
        {
          label: "15-21 ปี",
          value: "15-21",
        },
        {
          label: "22-39 ปี",
          value: "22-39",
        },
        {
          label: "40-59 ปี",
          value: "40-59",
        },
        {
          label: "60 ปีขึ้นไป",
          value: "60+",
        },
      ],
      occupationList: [
        "เกษตรกร",
        "ธุรกิจส่วนตัว",
        "นักเรียน / นักศึกษา",
        "รับจ้าง / พนักงานบริษัท",
        "ลูกจ้างของรัฐ / รัฐวิสาหกิจ",
      ],
      choices1: [
        {
          label: "ใช่",
          value: 1,
        },
        {
          label: "ไม่ใช่",
          value: 2,
        },
      ],
      choices2: [
        {
          label: "การขอสินเชื่อ",
          value: 1,
        },
        {
          label: "บริการ BAAC Connect",
          value: 2,
        },
        {
          label: "Chatbot ถามตอบบริการ ธ.ก.ส.",
          value: 3,
        },
        {
          label: "Chatbot หอมจังนักออมรอบรู้",
          value: 4,
        },
        {
          label: "บริการอื่นๆ ",
          value: 5,
        },
      ],

      choices3: [
        {
          label: "5",
          value: 5,
        },
        {
          label: "4",
          value: 4,
        },
        {
          label: "3",
          value: 3,
        },
        {
          label: "2",
          value: 2,
        },
        {
          label: "1",
          value: 1,
        },
      ],

      choices4: [
        {
          label: "1-2 ครั้ง",
          value: 1,
        },
        {
          label: "3-4 ครั้ง",
          value: 2,
        },
        {
          label: "5 ครั้ง",
          value: 3,
        },
      ],
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: [0, 1],
      },
    };
  },

  watch: {
    step() {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
  },

  mounted() {
    this.liffInit();
    window.addEventListener("scroll", this.onScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    async liffInit() {
      await liff.init({ liffId: "1566247186-Mm2BOvRR" });

      if (liff.isLoggedIn()) {
        // const isFriend = await this.getFriend();
        // if (!isFriend) {
        //   alert("กรุณาเพิ่มเพื่อน GHB Buddy");
        //   window.location = "https://line.me/R/ti/p/@ghbbuddy";
        // } else {
        //   await this.getProfile();
        // }
        await this.getProfile();
      } else {
        liff.login();
      }
    },

    async getFriend() {
      const friend = await liff.getFriendship();
      return friend.friendFlag;
    },

    async getProfile() {
      const profile = await liff.getProfile();
      this.profile = profile;
    },

    onSubmit() {
      this.submited = true;
      this.errorMessages = "";
      const form = document.querySelector("form");

      if (!form.checkValidity() || !this.checkValidity()) {
        form.classList.add("was-validated");
        const el = form.querySelector("*:invalid");
        if (el) el.scrollIntoView();
      } else {
        this.submit();
      }
    },

    checkValidity() {
      return (
        this.form.answer1_2 &&
        this.form.answer1_3 &&
        this.form.answer2_1 &&
        this.form.answer2_2 &&
        this.form.answer2_2 &&
        this.form.answer2_3 &&
        this.form.answer2_4 &&
        this.form.answer3_1 &&
        this.form.answer3_2 &&
        this.form.answer3_3 &&
        this.form.answer3_4 &&
        this.form.answer3_5 &&
        this.form.answer3_6 &&
        this.form.answer3_7 &&
        this.form.answer3_8 &&
        this.form.answer3_9 &&
        this.form.answer4_1 &&
        this.form.answer4_2 &&
        this.form.answer4_3 &&
        this.form.answer4_4 &&
        this.form.answer5_1 &&
        this.form.answer5_2 &&
        this.form.answer5_3 &&
        this.form.answer5_4 &&
        this.form.answer6_1 &&
        this.form.answer6_2
      );
    },

    async submit() {
      this.isLoading = true;
      this.form.user_id = this.profile.userId;
      this.form.accept_term = this.form.accept_term ? "ยอมรับ" : "ไม่ยอมรับ";

      this.form.answer2_4 = this.form.answer2_4.toString();

      const doc = new GoogleSpreadsheet(this.sheetId);
      await doc.useServiceAccountAuth(creds);
      await doc.loadInfo();
      const sheet = doc.sheetsByIndex[0];
      await sheet.addRow(this.form);

      this.isLoading = false;
      this.step = 3;

      setTimeout(() => {
        this.$refs.alert.scrollIntoView();
      }, 100);
    },

    success() {
      liff.closeWindow();
    },

    onWaypoint({ going }) {
      if (going === this.$waypointMap.GOING_IN) this.hasScrolledToBottom = true;
    },

    acceptTerm() {
      this.step = 2;
      this.form.accept_term = 1;
    },

    onScroll() {
      if (document.body.getBoundingClientRect().top < -68) {
        document.body.classList.add("header-sticky");
      } else {
        document.body.classList.remove("header-sticky");
      }
    },
  },
};
</script>

<style lang="scss">
.text {
  &-gradient {
    background: linear-gradient(91.77deg, #25659b -9.1%, #859f80 39.43%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.btn {
  &-gradient {
    border: none;
    background: linear-gradient(99.52deg, #25659b -39.24%, #97d18d 87.51%);
  }
}
body {
  max-width: 1024px;
  margin: auto;

  @media (min-width: 1025px) {
    background: #f8f8f8;
  }
}

.app {
  padding-bottom: 80px;
  background: #fff;
  min-height: 100vh;

  &[data-step="1"] {
    padding-bottom: 115px;
  }

  &-header {
    height: 4.25rem;
    background-color: #ff850c;
    background-size: auto 4.25rem;
    background-position: top right;
    background-repeat: no-repeat;
    display: none !important;
  }

  &-logo {
    height: 2.375rem;

    &-69 {
      height: 2.75rem;
    }
  }

  &-action {
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: saturate(200%) blur(20px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    @media (min-width: 1025px) {
      width: 1024px;
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
}

.choice-item {
  &:active {
    opacity: 0.5;
  }

  input:checked {
    user-select: none;
  }

  input:checked + svg {
    fill: var(--bs-primary);
  }

  input:disabled + svg {
    opacity: 0.3;

    & + span {
      opacity: 0.3;
    }
  }
}

.step-success {
  min-height: calc(100vh - 120px);
}

.mx-n12 {
  margin-left: -12px;
  margin-right: -12px;
}
</style>
